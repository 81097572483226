<template>
  <div class=" ">
    <div class="publicSearch">
      <div class="searchBar">
        <el-button-group>
          <el-button @click="getAsnData" size="mini" icon="el-icon-search">查询
          </el-button>
          <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">重置
          </el-button>
        </el-button-group>
        <div>
          <span>单号</span>
          <el-input style="width:100px" v-model="searchData.billNo" size="mini" placeholder="单号" clearable></el-input>
        </div>
        <div>
          <span>料号</span>
          <el-input v-model="searchData.itemCode" size="mini" placeholder="输入料号" clearable style="width:100px">
          </el-input>
        </div>
        <div>
          <span>入库类型</span>
          <el-select v-model="searchData.asnType" size="mini" filterable placeholder="请选择入库类型" clearable>
            <el-option v-for="item in asnTypes" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey">
            </el-option>
          </el-select>
        </div>
        <div>
          <span>供应商</span>
          <el-select v-model="searchData.supplierCode" size="mini" filterable placeholder="请选择供应商" clearable>
            <el-option v-for="(supplier, index) in suppliers" :key="index"
              :label="supplier.supplierName" :value="supplier.supplierCode">
            </el-option>
          </el-select>
        </div>
        <!-- <div>
          <span>旧料号</span>
          <el-input
              v-model="searchData.oldItemCode"
              size="mini"
              placeholder="旧料号"
              clearable
          ></el-input>
        </div> -->
        <div>
          <span>收货状态</span>
          <el-select v-model="searchData.asnState" size="mini" filterable placeholder="请选择收货状态" clearable>
            <el-option v-for="(item, index) in asnStates" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <span>工厂</span>
          <el-select v-model="searchData.organizationCode" size="mini" filterable placeholder="请选择工厂" clearable>
            <el-option v-for="(item, index) in organizations" :key="index"
              :label="item.organizationName" :value="item.organizationCode">
            </el-option>
          </el-select>
        </div>
        <!-- <div>
          <span>承运方</span>
          <el-select
              v-model="carrierCode"
              size="mini"
              filterable
              placeholder="请选择承运方"
              clearable
          >
            <el-option
                v-for="(item,index) in carriers"
                :key="index"
                :label="item.carrierName"
                :value="item.carrierCode"
            >
            </el-option>
          </el-select>
        </div> -->
        <div>
          <span>创建时间</span>
          <el-date-picker v-model="searchData.createTimeArr" size="mini" type="daterange" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div>
          <span>预计到货时间</span>
          <el-date-picker v-model="searchData.expectTimeArr" size="mini" type="daterange" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <!--
        <div>
          <span>优先级</span>
          <el-input
              v-model="searchData.orderNo"
              size="mini"
              placeholder="请输入优先级"
              clearable
          ></el-input>
        </div> -->
        <div>
          <span>关键字搜索</span>
          <el-input v-model="searchData.keyWords" size="mini" placeholder="关键字搜索" clearable></el-input>
        </div>
      </div>

    </div>
    <div class="headerBtns">
      <!-- <el-button type="primary" :disabled="!$parent.getPermission('addAsn')"
        @click="$router.push({ name: 'createAsnBill' })" size="small">
        新增
      </el-button> -->
      <!-- <el-button size="small" type="primary" @click="downloadModelFile">下载导入模板</el-button> -->
      <!-- <el-upload style="display:inline-block; margin-left:20px" class="upload-demo"
        action="http://localhost:8001/wms/bpm/inStore/uploadPo" :on-success="uploadComplete" :file-list="fileList"
        name="model">
        <el-button size="small" type="primary">导入订单</el-button>
      </el-upload> -->
      <!-- <el-button
          class="rightFloat"
          size="small"
          type="danger"  
          @click="noShipVisible=true;noShipInfo={}"
          >
        未录快递单
      </el-button>

      <el-button
          class="rightFloat"
          size="small"
          type="danger"  
          @click="allotAsn()"
          >
        分配
      </el-button>
      <el-dropdown style="margin-left:10px;margin-right:10px"  size="small"   class="rightFloat" type="primary"    icon="iconfont icon-print"  @click="pringAsnCode()" split-button  >
        打印快递码
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><el-button   size="small" type="primary"  @click="$parent.setPrinter">打印设置</el-button></el-dropdown-item>           
        </el-dropdown-menu>
      </el-dropdown>     
      <el-input  class="rightFloat" style="width:50px" v-model="asnPrintCode"></el-input>
       -->
      <!-- <el-button
          class="rightFloat"
          size="small"
          type="info"
          plain
          icon="iconfont icon-backup"
          @click="commitFile"
          :disabled="multipleSelection.length <= 0">
        归档
      </el-button> -->
    </div>
    <el-dialog title="录入供应商" :visible.sync="supplierInputVisible">
      <el-form>
        <el-form-item label="供应商名称" :label-width="formLabelWidth">
          <el-select v-model="asnMaster.supplierCode" size="mini" filterable placeholder="请选择供应商" clearable>
            <el-option v-for="(supplier, index) in suppliers" :key="index"
              :label="'（' + supplier.supplierCode + '）' + supplier.supplierName" :value="supplier.supplierCode">
              （{{ supplier.supplierCode }}）{{ supplier.supplierName }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="addSupplierCommit">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="备注" :visible.sync="memoVisible">
      <el-form :model="rowData">
        <el-form-item label="订单号" :label-width="formLabelWidth">
          <span>{{ memoData.billNo }}</span>
        </el-form-item>
        <el-form-item label="物流单号" :label-width="formLabelWidth">
          <span>{{ memoData.shipBillCode }}</span>
        </el-form-item>
        <el-form-item label="商家备注" :label-width="formLabelWidth">
          <span>{{ memoData.memo }}</span>
        </el-form-item>
        <el-form-item label="增加备注" :label-width="formLabelWidth">
          <el-input style="width:350px" v-model="memoData.memo2" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="memoVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitMemo">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="未录入快递单" :visible.sync="noShipVisible">
      <el-form :model="noShipInfo">
        <el-form-item label="工厂" :label-width="formLabelWidth">
          <el-select v-model="noShipInfo.organizationId" filterable placeholder="请选择工厂" style="width: 70%;">
            <el-option v-for="org in organizations" :key="org.organizationId"
              :label="org.organizationId + org.organizationName" :value="org.organizationId">
              ({{ org.organizationId }}){{ org.organizationName }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流公司" :label-width="formLabelWidth">
          <el-select v-model="noShipInfo.carrierCode" filterable placeholder="请选择快递公司" style="width: 70%;">
            <el-option v-for="item in carriers" :key="item.carrierCode" :label="item.carrierCode + item.carrierName"
              :value="item.carrierCode">
              ({{ item.carrierCode }}){{ item.carrierName }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" :label-width="formLabelWidth">
          <el-input v-model="noShipInfo.shipBillCode"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="noShipVisible = false">取 消</el-button>
        <el-button type="primary" @click="noShipCommit()">确 定</el-button>
      </div>
    </el-dialog>
    <div>
      <el-table :max-height="clientHeight - 280" :row-style="$parent.tableRowStyle" highlight-current-row stripe border
        :header-cell-style="$parent.tableHeaderColor" v-loading="loading.masterTable" :data="asnBills"
        @selection-change="handleSelectionChange" style="width: 100%">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div v-for="item in scope.row.details" :key="item.id" style="
                        margin-top: 2px;
                        padding:2px;
                        margin:5px;
                        width:500px;
                        margin-left:50px;
                        border:0.3px solid ; border-top:1px solid ;border-color:#d1c7b7
                        ">
              <el-row :gutter="20">
                <el-col :span="5">
                  <div style=" margin-left:5px;   width:63px;height:63px">
                    <el-image style="width: 63px; height: 63px; " :src="item.imgUrl ? (rtUrl + rlPath + item.imgUrl) : ''"
                      :preview-src-list="[rtUrl + rlPath + item.imgUrl]">
                    </el-image>
                  </div>
                </el-col>
                <el-col :span="19">
                  <div style=" margin-left:5px; ">
                    <div
                      style="color:#409EFF;width:350px;height:20pxwidth:120px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">
                      <span>编号：{{ item.itemCode }}</span><span style="margin-left:30px">名称：{{ item.itemName }}</span>
                    </div>
                    <!-- <div style="color:red"> 新：{{ item.itemCode }}</div>
                    <div style="color:red"> 旧：{{ item.oldItemCode }}</div> -->
                    <span>规格：{{ item.spec }}</span>
                    <br>
                    <span>型号：{{ item.model }}</span>
                    <br>
                    <span>采购数：{{ item.expectQuantity }}</span><span style="margin-left:30px">验收数：{{ item.acceptQuantity
                    }}</span>
                    <!-- <div style="">订单：{{item.orderSn}} 
                                           </div>
                                            <el-button  type="text"   @click="isDeleteDetail(item.asnDetailId)">删除</el-button> -->
                  </div>
                </el-col>
              </el-row>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip sortable prop="billNo" label="单号" width="130">
          <template slot-scope="scope">
            <div class="textBtn" @click="
              $router.push({
                name: 'asnBillDetail',
                query: { asnMasterId: scope.row.asnMasterId, billNo: scope.row.billNo },
              })
              ">{{ scope.row.billNo }}</div>

            <div>{{ scope.row.allotBillNo }}</div>
            <div v-if="scope.row.orderSn != null && scope.row.orderSn.length > 2">
              {{ scope.row.orderSn }}
              <br>
            </div>

            <div v-for="(item, index) in asnTypes" :key="index" v-if="scope.row.asnType === item.dictKey">
              {{ item.dictValue }}</div>

            <el-button v-if="scope.row.asnType == 0" @click="changeType(scope.row, 11)" size="mini" type="warning">改为无价值入库
            </el-button>
            <el-button v-if="scope.row.asnType == 11" @click="changeType(scope.row, 0)" size="mini" type="danger">改为手动创建
            </el-button>
            <!-- <div>
                           <span>{{scope.row.carrierName}}</span>
                             <br>
                              <span
                                  class="textBtn"
                                  @click="
                                      $router.push({
                                          name: 'fastAccept',
                                          params: scope.row,
                                      })
                                  "
                              >{{ scope.row.shipBillCode }}</span  >
                       
                              <a v-if="scope.row.shipBillCode!=null && scope.row.shipBillCode.length>3"  style="color:#E6A23C"
                               :href="'https://www.baidu.com/s?ie=UTF-8&wd='+scope.row.shipBillCode"  target="_blank"   >查询</a>
                        </div> -->
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="asnState" label="收货状态" width="80">
          <template slot-scope="scope">
            <span v-for="(item, index) in asnStates" v-if="scope.row.asnState == item.value"
              :style="'color:' + item.color">{{
                item.label }}</span>
            <!-- <span v-if="scope.row.asnState == -1" style="color: #909399">已取消</span>
            <span v-if="scope.row.asnState == 0" style="color: #909399">待收货</span>
            <span v-if="scope.row.asnState == 1" style="color: #409EFF">已扫描</span>
            <span v-if="scope.row.asnState == 2" style="color: #543044">部分收货</span>
            <span v-if="scope.row.asnState == 3" style="color: #67c23a">全部收货</span>
            <span v-if="scope.row.asnState == 4" style="color: red">关闭</span>
            <span v-if="scope.row.asnState == 5" style="color: red">超额收货</span> -->
          </template>
        </el-table-column>
        <!-- <el-table-column
            show-overflow-tooltip
            prop="asnState"
            label="货物信息"
            width="150"
        >
          <template slot-scope="scope" > 
            <div style="width:100%">
                 <div  v-for="item in scope.row.details" :key="item.id"   style="width:30px;float:left">
                  <el-image 
                    style="width: 20px; height: 20px"                  
                    :src="item.imgUrl" 
                    :preview-src-list="[item.imgUrl]">
                  </el-image>                    
                </div>
            </div> 
          </template>
        </el-table-column> -->

        <el-table-column show-overflow-tooltip prop="organizationName" label="工厂" min-width="80">
          <template slot-scope="scope">
            <span> {{ scope.row.organizationCode }} </span>
            <br />
            <span> {{ scope.row.organizationName }} </span>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="toOrganizationCode" label="调拨目标工厂">
          <template slot-scope="scope">
            <span>{{scope.row.toOrganizationCode}}</span>
            <br/>
            <span>{{scope.row.toOrganizationName}}</span>
          </template>
        </el-table-column> -->

        <el-table-column show-overflow-tooltip prop="supplierName" label="供应商" min-width="80">
          <template slot-scope="scope">
            <span> {{ scope.row.supplierCode }} </span>
            <br />
            <span> {{ scope.row.supplierName }} </span>

            <el-button v-if="scope.row.asnType === '11' && scope.row.supplierCode == undefined" type="text" size="small"
              @click="showSupplierInput(scope.row)">录入供应商</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column  
             prop="asnType"
             label="入库单类型"
             width="100">
             <template slot-scope="scope"> 
               <span v-for="(item,index) in asnTypes" :key="index" v-if="scope.row.asnType === item.dictKey">{{item.dictValue}}</span>
             </template>
           </el-table-column> -->

        <el-table-column show-overflow-tooltip prop="createUserName" label="创建人" min-width="80">
          <template slot-scope="scope">
            <span class="textBtn" @click="$parent.showPublicModal(scope.row.createUserId, 'account')" type="text">{{
              scope.row.createUserName }}</span>
            <div>
              录入时间：{{ scope.row.createTime.substr(5, 11) }}
              <!-- <div v-if="scope.row.asnState==0">
                                <span v-if="((new Date() -new Date(scope.row.createTime))/60/60/24/1000) > 3" style="color:#E6A23C">
                                  超时({{((new Date() -new Date(scope.row.createTime))/60/60/24/1000).toFixed(0)}}天)
                                </span>
                              </div> -->

            </div>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="purchaseReqUser" label="购买信息" min-width="80">
          <template slot-scope="scope">
            <div class="textBtn"
              @click="$router.push({ name: 'createSendGoods', params: { type: 'view', sendGoodsNo: scope.row.sendNo } })">
              {{
                scope.row.sendNo }}</div>
            <div v-if="scope.row.expectTime != null && scope.row.expectTime.length > 10">预计到期：{{
              scope.row.expectTime.substr(0, 10) }}</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="memo" label="仓库备注" min-width="60">

        </el-table-column>

        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <!-- <el-button :disabled="!$parent.getPermission('acceptButton')" v-if="scope.row.asnState != -1" size="mini"
              @click="
                $router.push({
                  name: 'asnBillDetail',
                  params: scope.row,
                })
                " type="primary">磅单
            </el-button> -->

            <el-button :disabled="!$parent.getPermission('acceptButton')" v-if="scope.row.asnState != -1 && scope.row.asnState != 4 " size="mini"
              @click="$router.push({ name: 'fastAccept', query: { asnMasterId: scope.row.asnMasterId }, })"
              type="primary">入库
            </el-button>
            <el-button :disabled="!$parent.getPermission('cancelButton')" v-if="scope.row.asnState == 0" size="mini"
              @click="isDelete(scope.row.asnMasterId)" type="danger">
              取消
            </el-button>
            <el-button :disabled="!$parent.getPermission('closeButton')"
              v-if="scope.row.asnState > 0  && scope.row.asnState != 4" size="mini" @click="isClose(scope.row.asnMasterId)"
              type="warning">
              关闭
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination-bottom" style="text-align: right" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" :current-page.sync="searchData.pageNum" :page-sizes="[5, 10, 15, 20, 25]"
        :page-size="searchData.pageSize" layout="sizes,total, prev, pager, next" :total="searchData.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  mapState
} from "vuex";

export default {
  name: "AsnBill",
  data() {
    return {
      typeKey: 'itemPathKey',
      upUrl: BASEIP + '/upload/files',
      rtUrl: BASEIP + "/file/files",
      rlPath: '',

      supplierInputVisible: false,
      asnMaster: {
        supplierCode: '',
        supplierName: ''
      },
      wares: [],

      noShipVisible: false,
      noShipInfo: {},
      memoData: {},
      memoVisible: false,
      asnPrintCode: 10,
      fileList: [],
      wlVisible: true,
      isMoreSearch: false,
      wareId: null,
      organizationId: PF.getLocal("organizationId"),
      organizationCode: PF.getLocal("organizationCode"),
      //  wareId: PF.getLocal("wareId", "number"),
      loading: {
        masterTable: true,
      },
      clientHeight: $(window).height(),
      rowData: {},
      carrierCode: null,
      suppliers: [],
      searchData: {
        itemCode: '',
        oldItemCode: '',
        pageNum: 1,
        pageSize: 10,
        total: null,
        keyWords: "",
        wareId: null,
        //  wareId: PF.getLocal("wareId", "number"),
        state: null,
        organizationCode: PF.getLocal("organizationCode"),
        supplierId: null,
        carrierId: null,
        supplierCode: '',
        asnState: null,
        billNo: "",
        billSource: "",
        asnType: "",
        platformCode: "",
        orderNo: "",
        createTimeArr: [],
        expectTimeArr: [],
        startCreateTime: "",
        endCreateTime: "",
        startExpectTime: "",
        endExpectTime: "",
      },
      asnBills: [],
      platforms: [],
      carriers: [],
      formLabelWidth: "120px",
      asnUdfHs: [],
      organizations: [],
      multipleSelection: [],
      asnTypes: [],
      billSourceOptions: [{
        value: "",
        label: "全部",
      },
      {
        value: "0",
        label: "采购单",
      },
      {
        value: "1",
        label: "新建",
      },
      {
        value: "2",
        label: "ERP导入",
      },
      {
        value: "4",
        label: "调拨",
      },
      ],
    };
  },
  watch: {
    carrierCode(newVal, oldVal) {
      this.searchData.carrierCode = newVal;

      this.getAsnData();
    }

  },
  computed: {
    ...mapState(["reviewStates", "asnStates", "qcStates"]),
  },
  methods: {
    showSupplierInput(asnMaster) {
      this.asnMaster = asnMaster
      this.supplierInputVisible = true
    },
    uploadComplete(res) {
      window.IOT.tips(res.message + ':' + res.data, 'info')
    },
    downloadModelFile() {
      window.location.href = '/download/订单导入表.xlsx'
    },

    pringAsnCode() {
      let that = this;
      window.IOT.getServerData('/asn/codes/print', 'get', {
        wareId: this.wareId,
        quantity: this.asnPrintCode
      }, (ret) => {
        if (ret.code === 200) {
          var strHTML = ret.data;
          that.$parent.printHtml(strHTML, null);
        } else {
          window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      }, true)
    },
    isDelete(asnMasterId) {
      let that = this;
      this.$confirm('确认取消吗？')
        .then(() => {
          that.deleteOrder(asnMasterId)
        })
        .catch(() => { });
    },
    isClose(asnMasterId) {
      let that = this;
      this.$confirm('关闭后不能修改,确认关闭吗？')
        .then(() => {
          that.closeOrder(asnMasterId)
        })
        .catch(() => { });
    },
    closeOrder(asnMasterId) {
      let that = this;
      window.IOT.showOverlay('提交中～～～')
      window.IOT.getServerData('/asn/masters/state4', 'get', { id: asnMasterId }, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) {
          window.IOT.tips('关闭成功！', 'success')
          that.getAsnData();
        } else {
          window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })
    },
    deleteOrder(asnMasterId) {
      let that = this;
      window.IOT.showOverlay('提交中～～～')
      window.IOT.getServerData('/asn/masters/cancel', 'get', { asnMasterId: asnMasterId }, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) {
          window.IOT.tips('取消成功！', 'success')
          that.getAsnData();
        } else {
          window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })
    },
    changeType(row, type) {
      let that = this;
      window.IOT.showOverlay('提交中～～～')
      window.IOT.getServerData('/asn/masters/changeType', 'get', {
        asnMasterId: row.asnMasterId,
        type: type
      }, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) {
          window.IOT.tips('成功！', 'success')
          that.getAsnData();
        } else {
          window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })
    },

    isDeleteDetail(id) {
      this.$confirm('确认关闭吗？ ')
        .then(() => {
          this.deleteAsnDetail(id)
        })
        .catch(() => { });
    },
    deleteAsnDetail(id) {
      let that = this;
      window.IOT.showOverlay('提交中～～～')
      window.IOT.getServerData('/asn/details/delete', 'get', {
        id: id
      }, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) {
          window.IOT.tips('删除成功！', 'success')
          that.getAsnData();
        } else {
          window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })
    },

    allotAsn() {
      window.IOT.showOverlay('提交中～～～')
      window.IOT.getServerData('/asn/details/allotAsn', 'get', {}, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) { } else {
          window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })
    },
    noShipCommit() {
      let that = this;
      var noShipInfo = this.noShipInfo;
      window.IOT.showOverlay('提交中～～～')
      IOT.getServerData("/no/ships/add", 'post', noShipInfo, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) {
          window.IOT.tips('提交成功！', 'success')
          that.noShipVisible = false;
          that.noShipInfo = {};
        } else {

        }
      });

    },

    addSupplierCommit() {
      let that = this;
      window.IOT.showOverlay('提交中～～～')
      IOT.getServerData("/asn/masters/addSupplier", 'post', this.asnMaster, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) {
          window.IOT.tips('提交成功！', 'success')
          that.supplierInputVisible = false
          that.getAsnData()
        } else {
          window.IOT.tips('', '')
        }
      });
    },

    getAsnDetailData(asnMasterId) {
      let that = this;
      let url = url = '/asn/details/list';
      IOT.getServerData(url, 'get', { asnMasterId: asnMasterId }, (ret) => {
        if (ret.code === 200) {
          let list = ret.rows;
          that.asnBills.forEach(order => {
            if (order.asnMasterId == asnMasterId) {
              order.details = list;
            }
          });
          //var asnBills = PF.JSON(that.asnBills);
          //this.asnBills = [];
          that.asnBills = PF.JSON(that.asnBills);
        } else {

        }
      });
    },
    commitMemo() {
      var that = this;
      that.memoVisible = false;
      window.IOT.showOverlay('提交中～～～')
      window.IOT.getServerData('/asn/masters/memo', 'get', {
        asnMasterId: that.memoData.asnMasterId,
        memo2: that.memoData.memo2

      }, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) {
          window.IOT.tips('提交成功！', 'success')
          this.getAsnData();
        } else {
          window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })
    },

    getWlgj(row) {
      window.IOT.getServerData('/kuaidiniaos/jscx', 'get', {
        expCode: row.carrierCode,
        expNo: row.shipBillCode
      }, (ret) => {
        window.IOT.hideOverlay()
      }, true)
    },
    updateShip(asn) {
      var that = this;
      that.rowData = asn;
      that.shipFormVisible = true;
    },
    commitShip() {
      var that = this;
      window.IOT.showOverlay('提交中～～～')
      window.IOT.getServerData('/asn/masters/addShipBillCode', 'post', {
        billNo: that.rowData.billNo,
        asnMasterId: that.rowData.asnMasterId,
        shipBillNo: that.rowData.shipBillCode,
        shipCode: that.rowData.carrierCode
      }, (ret) => {
        window.IOT.hideOverlay()
        if (ret.code === 200) {
          window.IOT.tips('提交成功！', 'success')
          that.getAsnData();
        } else {
          window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })

    },
    // isDelete(asnMasterId) {
    //             this.$confirm('确认删除吗？')
    //                 .then(() => {
    //                     this.deleteOrder(asnMasterId)
    //                 })
    //                 .catch(() => {});
    //         },
    //       deleteOrder(asnMasterId) {
    //           window.IOT.showOverlay('提交中～～～')
    //           window.IOT.getServerData('/asn/masters/delete', 'get', {id: asnMasterId}, (ret) => {
    //               window.IOT.hideOverlay()
    //               if (ret.code === 200) {
    //                   window.IOT.tips('删除成功！', 'success')
    //                   this.getAsnData();
    //               } else {
    //                   window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
    //               }
    //           })
    //       },
    handleSizeChange(val) {
      this.searchData.pageSize = val;
      this.getAsnData();
    },

    handleCurrentChange(val) {
      this.searchData.pageNum = val;
      this.getAsnData();
    },
    clearSearchData() {
      this.searchData = {
        itemCode: '',
        oldItemCode: '',
        pageNum: 1,
        pageSize: 10,
        total: null,
        keyWords: "",
        wareId: null,
        state: null,
        organizationCode: '',
        organizationId: null,
        supplierId: null,
        carrierId: null,
        supplierCode: '',
        asnState: null,
        billNo: "",
        billSource: "",
        asnType: null,
        platformCode: "",
        orderNo: "",
        createTimeArr: [],
        expectTimeArr: [],
        createTimeArr: [],
      };

      this.carrierCode = null;
    },
    getAsnData() {
      var that = this;
      let searchData = PF.JSON(this.searchData);
      PF.setLocal("asnMastersearchData", searchData);
      that.loading.masterTable = true;
      IOT.getServerData("/asn/masters/list", "get", searchData, (ret) => {
        that.loading.masterTable = false;
        if (ret.code === 200) {
          var rows = ret.rows;
          rows.forEach(row => {
            var memo = row.memo;
            var memos = [];
            if (memo != null && memo.length > 0) {
              memos = memo.split("&");
              memo = memos[memos.length - 1];
              row.memo = memo;
              row.memos = memos;
            }
          });


          rows.forEach(row => {
            var memo2 = row.memo2;
            var memos2 = [];
            if (memo2 != null && memo2.length > 0) {
              memos2 = memo2.split("&");
              memo2 = memos2[memos2.length - 1];
              row.memo2 = memo2;
              row.memos2 = memos2;
            }
          });
          that.asnBills = rows;
          that.asnBills.forEach(order => {
            that.getAsnDetailData(order.asnMasterId);
          });
          that.searchData.total = ret.total;
          that.searchData.pageNum = ret.pageNumber;
        } else {
          IOT.tips(
            ret.message || "服务器请求失败，稍后再试！",
            "error"
          );
        }
      });
    },
    getPlatformData() {
      IOT.getServerData("/platforms/findByWareId", "get", { wareId: PF.getLocal("wareId", "number") }, (ret) => {
        if (ret.code === 200) {
          this.platforms = ret.rows;
        } else {
          IOT.tips(
            ret.message || "服务器请求失败，稍后再试！",
            "error"
          );
        }
      }
      );
    },
    getCarrierData() {
      IOT.getServerData("/carriers/allList", "get", {}, (ret) => {
        if (ret.code === 200) {
          this.carriers = ret.rows;
        } else {
          IOT.tips(
            ret.message || "服务器请求失败，稍后再试！",
            "error"
          );
        }
      });
    },
    resetForm(formName) {
      // 重置
      this.$refs[formName].resetFields();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    commitFile() {
      let that = this;
      IOT.showOverlay("保存中，请稍等...");
      let list = PF.JSON(this.multipleSelection);
      let billNos = [];
      for (let i = 0; i < list.length; i++) {
        billNos.push(list[i].billNo);
      }
      IOT.getServerData("/asn/masters/file", "post", { billNos: billNos }, (ret) => {
        IOT.hideOverlay();
        that.multipleSelection = [];
        if (ret.code === 200) {
          IOT.tips("归档成功！", "success");
          that.getAsnData();
        } else {
          IOT.tips(
            ret.message || "服务器请求失败，稍后再试！",
            "error"
          );
        }
      }
      );
    },
    getCode() {
      var that = this;
      document.onkeypress = function (e) {
        that.getAsnData();
      };
    },
  },
  created() {
    let that = this;
    var searchData = PF.getLocal("asnMastersearchData", "json");

    if (searchData != null) {
      searchData.organizationCode = PF.getLocal("organizationCode");

      this.searchData = searchData;
    }
    this.getAsnData();
    PF.getSysParamUdfData("asnUdfHs", [], (data, udfs) => {
      that.asnUdfHs = udfs;
    });
    PF.getOrganizations((rows) => {
      that.organizations = rows;
    });
    PF.getSuppliers((rows) => {
      that.suppliers = rows;
    });
    PF.getDataDictUdfData("asnType", (rows) => {
      that.asnTypes = rows;
    });
    PF.getCarriers((rows) => {
      that.carriers = rows;
    });
    PF.getWares((rows) => {
      that.wares = rows;
    });
    PF.getRelativePath(this.typeKey, (path) => {
      that.rlPath = path;
    });

    //  this.getCode();
  },
  mounted() { },
};
</script>

<style scoped lang="less">
.asnBill {}

.upload-demo {
  margin-left: 0px !important;
}
</style>